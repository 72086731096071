import React from "react";

import { Navbar, Hero, Footer } from "./components";

const App = () => {
  return (
    <>
      <Hero />
    </>
  );
};

export default App;
