import React, { useState } from "react";
import "./hero.css";
import { SketchPicker } from "react-color";

import dropdownArrow from "../../assets/dropdownArrow.png";
import colorWheel from "../../assets/colorWheel.png";
import rafflesImg from "../../assets/rafflesImg.png";
import vouchersImg from "../../assets/vouchersImg.png";
import jackpotsImg from "../../assets/jackpotsImg.png";
import giftShopImg from "../../assets/giftShopImg.png";
import foodDrinksImg from "../../assets/foodDrinksImg.png";
import viewAllImg from "../../assets/viewAllImg.png";
import placeholderProfileImg from "../../assets/placeholderProfileImg.jpg";

const Hero = () => {
  const [dropdownEnabled, setDropdownEnabled] = useState(false);
  const [backdrop, setBackdrop] = useState("Dark"); // ["light", "dark"

  const handleBackdropSelection = (selection) => {
    setBackdrop(selection);
    setDropdownEnabled(false);
  };

  // Step 1: Define color state
  const [colors, setColors] = useState({
    bg: "#FFFFFF",
    bgAccent: "#BBBBBB",
    bgLight: "#CCCCCC",
    text: "#101010",
    textLight: "#868686",
    primaryAccent: "#622B83",
    primaryAccentLight: "#735387",
    primaryAccentDark: "#4A0872",
    secondaryAccent: "#D0B344",
    secondaryAccentLight: "#FFD949",
    secondaryAccentDark: "#E3B400",
    positive: "#42774F",
    positiveContrast: "#FFFFFF",
    negative: "#A5362F",
    negativeContrast: "#FFFFFF",
    heading: "#FFFFFF",
  });

  // Step 2: Handler for changing color
  const handleColorChange = (colorKey, value) => {
    setColors((prevColors) => ({
      ...prevColors,
      [colorKey]: value,
    }));
  };

  // Step 1: Create a state for color picker visibility
  const [colorPickerVisibility, setColorPickerVisibility] = useState({});

  // Step 2: Toggle color picker visibility
  const toggleColorPicker = (colorKey) => {
    setColorPickerVisibility((prevState) => ({
      ...prevState,
      [colorKey]: !prevState[colorKey],
    }));
  };

  return (
    <section
      id="hero"
      className={backdrop === "Dark" ? "darkMode" : "lightMode"}
    >
      <div id="selectionBar">
        <p className="selectionBar-label">
          Light or dark color scheme? Determines backdrop of this app
        </p>
        <div className="selectionBar-dropdown">
          <p>{backdrop}</p>
          <img
            src={dropdownArrow}
            alt=""
            onClick={() => setDropdownEnabled(!dropdownEnabled)}
          />
          {dropdownEnabled && (
            <div className="selectionBar-dropdown-expanded">
              <p onClick={() => handleBackdropSelection("Light")}>Light</p>
              <p onClick={() => handleBackdropSelection("Dark")}>Dark</p>
            </div>
          )}
        </div>
        {Object.keys(colors).map((colorKey) => (
          <div key={colorKey} className="selectionBar-item">
            <p htmlFor={colorKey}>{colorKey}</p>
            <div className="selectionBar-item-selector">
              <div className="selectionBar-item-colorLabel">
                <div
                  className="selectionBar-item-swatch"
                  style={{ background: colors[colorKey] }}
                />
                <p>{colors[colorKey]}</p>
              </div>
              <img
                src={colorWheel}
                alt=""
                onClick={() => toggleColorPicker(colorKey)}
              />
              {colorPickerVisibility[colorKey] && (
                <SketchPicker
                  color={colors[colorKey]}
                  onChange={(color) => handleColorChange(colorKey, color.hex)}
                  className="selectionBar-item-colorPicker"
                />
              )}
            </div>
          </div>
        ))}
      </div>
      <div id="previewSection">
        <div
          className="previewSection-screen"
          id="loginScreen"
          style={{
            background: `linear-gradient(to bottom, ${colors.primaryAccentDark}, ${colors.primaryAccent}, ${colors.primaryAccentLight})`,
          }}
        >
          <h1>[ANIMATION]</h1>
          <h2 style={{ color: colors.secondaryAccent }}>TAP CARD TO LOG IN</h2>
        </div>
        <div
          className="previewSection-screen"
          id="welcomeScreen"
          style={{
            background: colors.bg,
          }}
        >
          <h1 style={{ color: colors.primaryAccent }}>WELCOME!</h1>
        </div>
        <div
          className="previewSection-screen"
          id="dashboard"
          style={{
            background: colors.bg,
          }}
        >
          <div
            className="adBanner"
            style={{
              background: colors.bgLight,
              border: `1px solid ${colors.bgAccent}`,
            }}
          >
            <h1>[AD BANNER]</h1>
            <h2 style={{ color: colors.bg, background: colors.primaryAccent }}>
              View More
            </h2>
          </div>
          <div className="dashboardRow" style={{ height: "42.5%" }}>
            <div
              className="dashboardItem"
              style={{
                background: colors.bgLight,
                border: `1px solid ${colors.bgAccent}`,
                flex: 0.8,
              }}
            >
              <img src={rafflesImg} alt="" />
              <h1 style={{ color: colors.primaryAccent }}>Raffles</h1>
            </div>
            <div className="dashboardColumn" style={{ flex: 1 }}>
              <div
                className="dashboardItem"
                style={{
                  background: colors.bgLight,
                  border: `1px solid ${colors.bgAccent}`,
                  flex: 1,
                }}
              >
                <img src={vouchersImg} alt="" />
                <h1 style={{ color: colors.primaryAccent }}>Vouchers</h1>
              </div>
              <div className="dashboardRow" style={{ flex: 0.6 }}>
                <div
                  className="dashboardItem"
                  style={{
                    background: colors.bgLight,
                    border: `1px solid ${colors.bgAccent}`,
                  }}
                >
                  <img src={jackpotsImg} alt="" />
                  <h1 style={{ color: colors.primaryAccent }}>Jackpots</h1>
                </div>
                <div
                  className="dashboardItem"
                  style={{
                    background: colors.bgLight,
                    border: `1px solid ${colors.bgAccent}`,
                  }}
                >
                  <img src={giftShopImg} alt="" />
                  <h1 style={{ color: colors.primaryAccent }}>Gift Shop</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="dashboardRow" style={{ height: "15%" }}>
            <div
              className="dashboardItem"
              style={{
                background: colors.bgLight,
                border: `1px solid ${colors.bgAccent}`,
              }}
            >
              <img src={foodDrinksImg} alt="" />
              <h1 style={{ color: colors.primaryAccent }}>Food & Drinks</h1>
            </div>
            <div
              className="dashboardItem"
              style={{
                background: colors.bgLight,
                border: `1px solid ${colors.bgAccent}`,
              }}
            >
              <img src={viewAllImg} alt="" />
              <h1 style={{ color: colors.primaryAccent }}>View All</h1>
            </div>
          </div>
          <div
            className="globalInterface"
            style={{
              background: colors.bgLight,
              borderTop: `2px solid ${colors.primaryAccent}`,
            }}
          >
            <img src={placeholderProfileImg} alt="" />
            <div className="globalInterface-section">
              <h1 style={{ color: colors.text }}>John Doe</h1>
              <h2 style={{ color: colors.textLight }}>Yellow Card</h2>
              <p
                style={{
                  background: `linear-gradient(to right, ${colors.primaryAccentDark}, ${colors.primaryAccent}, ${colors.primaryAccentLight})`,
                  color: colors.secondaryAccent,
                }}
              >
                MANAGE PROFILE
              </p>
            </div>
            <div
              className="globalInterface-section"
              style={{ color: colors.text }}
            >
              <h3>Cash</h3>
              <h3>Promo</h3>
              <h3>B-Points</h3>
              <p
                style={{
                  background: `linear-gradient(to right, ${colors.primaryAccentDark}, ${colors.primaryAccent}, ${colors.primaryAccentLight})`,
                  color: colors.secondaryAccent,
                }}
              >
                CONVERT POINTS
              </p>
            </div>
            <div className="globalInterface-section">
              <h1>[POINTS SPRITE]</h1>
              <p
                style={{
                  background: `linear-gradient(to right, ${colors.primaryAccentDark}, ${colors.primaryAccent}, ${colors.primaryAccentLight})`,
                  color: colors.secondaryAccent,
                }}
              >
                TIER BENEFITS
              </p>
            </div>
            <div className="globalInterface-controls">
              <div
                className="languageSelector"
                style={{ background: colors.bg }}
              >
                <h2 style={{ color: colors.primaryAccent }}>English</h2>
              </div>
              <div
                className="logOutButton"
                style={{ background: colors.negative }}
              >
                <h2 style={{ color: colors.negativeContrast }}>LOG OUT</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
